import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

on('change', '.js-welcome-message-checkbox', (event: Event) => {
  const input = event.target
  const welcomeMessageForm = document.querySelector<HTMLElement>('.js-welcome-message-form')

  if (!isCheckbox(input) || !welcomeMessageForm) return

  const displayWelcomeMessageForm = input.checked
  welcomeMessageForm.hidden = !displayWelcomeMessageForm
})

on('change', '.js-sponsors-only-repository-checkbox', (event: Event) => {
  const input = event.target
  const repositoriesMenuWrapper = document.querySelector<HTMLElement>('.js-sponsors-only-repo-menu-wrapper')

  if (!isCheckbox(input) || !repositoriesMenuWrapper) return

  const displayRepositoryList = input.checked
  repositoriesMenuWrapper.hidden = !displayRepositoryList
})

// If a repository or "none" is selected from the menu, then we know the input is valid, so we can remove the error
on('change', '.js-sponsors-only-repo-menu', () => {
  const errors = document.querySelector<HTMLElement>('.js-sponsors-only-repository-errors')
  if (!errors) return
  errors.hidden = true
})

on('change', '.js-sponsors-country-select', (event: Event) => {
  const select = event.target as HTMLSelectElement
  const container = select.form
  if (!container) return
  const flag = container.querySelector<HTMLElement>('.js-sponsors-country-flag')
  if (!flag) return
  const selectedCountryCode = select.value
  const flagCountryCode = flag.getAttribute('data-country-code')
  flag.hidden = selectedCountryCode !== flagCountryCode
})

/**
 * Handle drag-and-drop reordering.
 */
observe('.js-sponsors-sortable-list', {
  async add(el) {
    const {Sortable} = await import('../sortable-behavior')
    Sortable.create(el, {
      animation: 150,
      item: '.js-sponsors-sortable-item',
      handle: '.js-sponsors-sortable-item-handle',
      chosenClass: 'is-dragging',
    })
  },
})

// eslint-disable-next-line i18n-text/no-en
const GOAL_TYPE_SPONSORS_LABEL = `Number of sponsors you're aiming for:`
// eslint-disable-next-line i18n-text/no-en
const GOAL_TYPE_SPONSORSHIP_LABEL = `Monthly amount you're aiming for:`

// Toggles the featured sponsorships settings if featured sponsorships is enabled
async function toggleFeaturedSponsorships({currentTarget}: {currentTarget: Element}) {
  const featuredSponsorshipsCheckbox = currentTarget as HTMLInputElement

  const settingsContainer = document.querySelector('.js-sponsors-settings-featured-sponsorships') as HTMLElement
  if (!settingsContainer) return

  settingsContainer.hidden = !featuredSponsorshipsCheckbox.checked
}

// Toggles the manual selection featured sponsorships setting if automatic selection is disabled.
async function toggleManualFeaturedSponsorships({currentTarget}: {currentTarget: Element}) {
  const featuredSponsorshipsCheckbox = currentTarget as HTMLInputElement

  const manualContainer = document.querySelector('.js-sponsors-manual-featured-sponsorships') as HTMLElement
  if (!manualContainer) return

  manualContainer.hidden = featuredSponsorshipsCheckbox.value === 'automatic'
}

// Toggles the Sponsors Goals form in the Dashboard based on the Goal type selected.
async function toggleSponsorsGoalsForm({currentTarget}: {currentTarget: Element}) {
  const goalTypeRadioInput = currentTarget as HTMLInputElement

  const formContainer = document.querySelector('.js-sponsors-goals-radio-target') as HTMLElement
  if (!formContainer) return

  formContainer.hidden = false

  const goalTypeLabel = formContainer.querySelector<HTMLElement>('.js-sponsors-goals-type-label')!
  if (goalTypeLabel) {
    const sponsorshipPerMonthLabel = formContainer.querySelector<HTMLElement>(
      '.js-sponsors-goals-sponsorship-per-month-label',
    )!
    const sponsorshipCurrencySign = formContainer.querySelector<HTMLElement>(
      '.js-sponsors-goals-sponsorship-currency-sign',
    )!
    const acceptPublicSponsorshipContainer = formContainer.querySelector<HTMLElement>(
      '.js-sponsors-goals-accept-public-sponsorship',
    )!
    const acceptPublicSponsorshipCheckbox =
      acceptPublicSponsorshipContainer.querySelector<HTMLInputElement>('input[type=checkbox]')!
    const submitButton = formContainer.querySelector<HTMLButtonElement>('button[type=submit]')!

    if (goalTypeRadioInput.value === 'total_sponsors_count') {
      goalTypeLabel.textContent = GOAL_TYPE_SPONSORS_LABEL
      sponsorshipPerMonthLabel.hidden = true
      sponsorshipCurrencySign.hidden = true
      acceptPublicSponsorshipContainer.hidden = true
      acceptPublicSponsorshipCheckbox.required = false
    } else {
      goalTypeLabel.textContent = GOAL_TYPE_SPONSORSHIP_LABEL
      sponsorshipPerMonthLabel.hidden = false
      sponsorshipCurrencySign.hidden = false
      acceptPublicSponsorshipContainer.hidden = false
      acceptPublicSponsorshipCheckbox.required = true
    }

    submitButton.disabled = !submitButton.form?.checkValidity()

    const goalTargetValueInput = document.querySelector<HTMLElement>('.js-sponsors-goal-target-input')!
    goalTargetValueListener(goalTargetValueInput)
  }
}

on('change', '.js-sponsors-goals-radio-trigger', toggleSponsorsGoalsForm)
on('change', '.js-sponsors-enable-featured-sponsorships', toggleFeaturedSponsorships)
on('change', '.js-sponsors-automate-featured-sponsorships', toggleManualFeaturedSponsorships)

// Updates the goal target value preview
async function goalTargetValueListener(input: Element) {
  const goalTargetValueInput = input as HTMLInputElement
  const goalTypeLabel = document.querySelector<HTMLElement>('.js-sponsors-goals-type-label')!
  const goalTitleActive = document.querySelector<HTMLElement>('.js-sponsors-goal-target-preview')!
  const goalTitleProgress = document.querySelector<HTMLElement>('.js-sponsors-goal-target-progress-preview')!

  let goalTitle
  if (goalTargetValueInput.value.trim() === '') {
    goalTitle = ''
  } else if (goalTypeLabel.textContent === GOAL_TYPE_SPONSORSHIP_LABEL) {
    goalTitle = `$${goalTargetValueInput.value} per month`
  } else {
    goalTitle = `${goalTargetValueInput.value} monthly sponsor`
    if (parseInt(goalTargetValueInput.value) > 1) goalTitle += 's'
  }

  goalTitleProgress.textContent = goalTitle
  if (goalTypeLabel.textContent === GOAL_TYPE_SPONSORSHIP_LABEL) {
    goalTitleActive.textContent = `earn ${goalTitle}`
  } else {
    goalTitleActive.textContent = `have ${goalTitle}`
  }

  // Update progress bar
  goalProgressBarListener(goalTargetValueInput.value, goalTypeLabel.textContent || '')
}

// eslint-disable-next-line i18n-text/no-en
const NO_GOAL_DESCRIPTION_PLACEHOLDER = `No description yet`

// Updates the goal description preview
async function goalDescriptionListener(input: Element) {
  const goalDescriptionInput = input as HTMLTextAreaElement
  const goalDescription = document.querySelector<HTMLElement>('.js-sponsors-goal-description-preview')!

  if (goalDescriptionInput.value.trim() === '') {
    goalDescription.textContent = NO_GOAL_DESCRIPTION_PLACEHOLDER
  } else {
    goalDescription.textContent = goalDescriptionInput.value
  }
}

// Updates the goal progress bar during preview
async function goalProgressBarListener(targetValue: string, goalType: string) {
  const goalPercentageText = document.querySelector<HTMLElement>('.js-sponsors-goal-percentage-preview')!
  const goalPercentageBar = document.querySelector<HTMLElement>('.js-sponsors-goal-percentage-bar-preview')!

  let percentage = 0
  const targetValueInt = parseInt(targetValue, 10)
  if (targetValueInt && targetValueInt > 0) {
    let currentValue = 0
    if (goalType === GOAL_TYPE_SPONSORSHIP_LABEL) {
      const monthlySponsorship = goalPercentageBar.getAttribute('data-preview-current-value-sponsorships') || '0'
      currentValue = parseInt(monthlySponsorship, 10)
    } else {
      const totalSponsors = goalPercentageBar.getAttribute('data-preview-current-value-sponsors') || '0'
      currentValue = parseInt(totalSponsors, 10)
    }

    percentage = Math.floor((currentValue * 100) / targetValueInt)
    if (percentage === 0 && currentValue > 0) percentage = 1

    percentage = Math.min(percentage, 100)
  }

  goalPercentageText.textContent = `${percentage}%`
  goalPercentageBar.style.width = `${percentage}%`
}

observe('.js-sponsors-goal-target-input', {
  add(el) {
    el.addEventListener('input', ({target}: Event) => goalTargetValueListener(target as Element))
  },
})

observe('.js-sponsors-goal-description-input', {
  add(el) {
    el.addEventListener('input', ({target}: Event) => goalDescriptionListener(target as Element))
  },
})

const isCheckbox = (eventTarget: EventTarget | null): eventTarget is HTMLInputElement => {
  if (eventTarget === null) return false

  return (eventTarget as HTMLInputElement).type === 'checkbox'
}

on('change', '.js-sponsors-opt-out-all-checkbox', (event: Event) => {
  const input = event.target
  if (!isCheckbox(input)) return

  const container = input.closest('.js-sponsors-opt-out-container')
  if (!container) return

  const checkboxes = container.querySelectorAll<HTMLInputElement>('.js-sponsors-email-preference-checkbox')
  for (const checkbox of checkboxes) {
    checkbox.checked = !input.checked
  }
})

on('change', '.js-sponsors-email-preference-checkbox', (event: Event) => {
  const input = event.target
  if (!isCheckbox(input)) return

  const container = input.closest('.js-sponsors-opt-out-container')
  if (!container) return

  const optOutAllCheckbox = container.querySelector<HTMLInputElement>('.js-sponsors-opt-out-all-checkbox')
  if (!optOutAllCheckbox) return

  const checkedBoxes = container.querySelectorAll<HTMLInputElement>('.js-sponsors-email-preference-checkbox:checked')
  optOutAllCheckbox.checked = checkedBoxes.length === 0
})
