import {on} from 'delegated-events'

function setHiddenFieldsForChosenPayout(form: Element, payoutEl: Element) {
  const stripeIDField = form.querySelector<HTMLInputElement>('.js-sponsors-stripe-id')!
  const payoutStartDateUnixField = form.querySelector<HTMLInputElement>('.js-sponsors-payout-start-date-unix')!

  const stripeID = payoutEl.getAttribute('data-stripe-account-id')!
  stripeIDField.value = stripeID

  const payoutStartDateUnix = payoutEl.getAttribute('data-payout-start-date-unix')
  if (payoutStartDateUnix) {
    payoutStartDateUnixField.value = payoutStartDateUnix
  } else {
    payoutStartDateUnixField.value = ''
  }
}

function updatePayoutMenuText(form: Element, payoutInput: HTMLInputElement) {
  const chosenPayoutEl = form.querySelector('.js-sponsors-chosen-payout')!
  if (payoutInput.checked) {
    const payoutContainer = payoutInput.closest('.js-sponsors-stripe-payout-container')!
    const payoutTextEl = payoutContainer.querySelector('.js-sponsors-stripe-payout-text')!
    chosenPayoutEl.textContent = payoutTextEl.textContent
  } else {
    const defaultText = chosenPayoutEl.getAttribute('data-default-text')!
    chosenPayoutEl.textContent = defaultText
  }
}

function updateSubmitButton(form: Element, payoutInput: HTMLInputElement) {
  if (!payoutInput.checked) return

  const submitButton = form.querySelector<HTMLButtonElement>('.js-sponsors-generate-receipt-button')!
  if (submitButton.disabled) submitButton.disabled = false
}

function clearPreviousSelection(form: Element, selector: string) {
  const radioInputs = form.querySelectorAll<HTMLInputElement>(selector)

  for (const radio of radioInputs) {
    radio.checked = false
  }
}

on('change', '.js-sponsors-stripe-payout', ({currentTarget}) => {
  const form = currentTarget.closest('.js-sponsors-payout-receipt-form')
  if (!form) {
    return
  }

  setHiddenFieldsForChosenPayout(form, currentTarget)
  updatePayoutMenuText(form, currentTarget as HTMLInputElement)
  updateSubmitButton(form, currentTarget as HTMLInputElement)
})

on('click', '.js-sponsors-stripe-payout', ({currentTarget}) => {
  const form = currentTarget.closest('.js-sponsors-payout-receipt-form')
  if (!form) {
    return
  }
  clearPreviousSelection(form, '.js-sponsors-payout-year')
})

on('change', '.js-sponsors-payout-year', ({currentTarget}) => {
  const form = currentTarget.closest('.js-sponsors-payout-receipt-form')
  if (!form) {
    return
  }

  updatePayoutMenuText(form, currentTarget as HTMLInputElement)
  updateSubmitButton(form, currentTarget as HTMLInputElement)
})

on('click', '.js-sponsors-payout-year', ({currentTarget}) => {
  const form = currentTarget.closest('.js-sponsors-payout-receipt-form')
  if (!form) {
    return
  }
  clearPreviousSelection(form, '.js-sponsors-stripe-payout')
})
