import {controller, target} from '@github/catalyst'
import type {PrimerMultiInputElement} from '@primer/view-components/app/lib/primer/forms/primer_multi_input'

@controller
class CountryAndRegionSelectionElement extends HTMLElement {
  @target declare regionSelectionContainer: HTMLElement
  @target declare defaultRegionSelect: HTMLSelectElement

  get regionMultiInputElement(): PrimerMultiInputElement {
    // We don't allow setting attributes in primer forms on the actual `primer-multi-input`,
    // only the container, so we have to do this for now :(
    return this.regionSelectionContainer.querySelector('primer-multi-input')!
  }

  handleCountrySelection(event: Event) {
    const selectElement = event.currentTarget as HTMLSelectElement
    const selectedOption = selectElement.selectedOptions[0]
    const regionSelectElementName = selectedOption?.getAttribute('data-region-select-element-name')

    this.regionMultiInputElement.activateField(
      regionSelectElementName ? regionSelectElementName : this.defaultRegionSelect.name,
    )
  }
}
