import {on} from 'delegated-events'

on('change', '.js-tier-suggestion', ({currentTarget}) => {
  const category = currentTarget.getAttribute('data-tier-category')!
  const allChecked = checkSiblingsSelected(category)
  updateBatchSelectButton(allChecked, category)
  buildInteractions(currentTarget as HTMLInputElement)
  resetTierPreviews()
})

on('click', '.js-tier-suggestion-batch-select', ({currentTarget}) => {
  if (currentTarget.getAttribute('data-function') === 'select') {
    selectAllTiersForCategory(currentTarget as HTMLSelectElement, currentTarget.getAttribute('data-tier-category')!)
  } else if (currentTarget.getAttribute('data-function') === 'deselect') {
    deselectAllTiersForCategory(currentTarget as HTMLSelectElement, currentTarget.getAttribute('data-tier-category')!)
  }
  resetTierPreviews()
})

function checkSiblingsSelected(category: string) {
  const allSelectionsInCategory = document.querySelectorAll<HTMLInputElement>(`input[data-tier-category='${category}']`)
  const allChecked = Array.from(allSelectionsInCategory).every(input => input.checked)
  return allChecked
}

function updateBatchSelectButton(allChecked: boolean, category: string) {
  const batchSelectButton = document.querySelector<HTMLButtonElement>(`button[data-tier-category='${category}']`)!

  if (allChecked) {
    batchSelectButton.setAttribute('data-function', 'deselect')
    batchSelectButton.textContent = batchSelectButton.getAttribute('data-all-selected-text')
  } else {
    batchSelectButton.setAttribute('data-function', 'select')
    batchSelectButton.textContent = batchSelectButton.getAttribute('data-not-all-selected-text')
  }
}

function resetTierPreviews() {
  hideAllTierPreviews()
  showCheckedTierPreviews()
}

function hideAllTierPreviews() {
  const noRewardsSelected = document.getElementById('no-rewards-selected')!
  const previewTiers = document.querySelectorAll<HTMLElement>('.js-preview-tier')
  const previewTierSuggestions = document.querySelectorAll<HTMLElement>('.js-preview-tier-suggestion')
  const previewTierRewards = document.querySelectorAll<HTMLElement>('.js-preview-tier-suggestion-reward')

  noRewardsSelected.hidden = true

  for (const tier of previewTiers) {
    tier.hidden = true
  }

  for (const tierSuggestion of previewTierSuggestions) {
    tierSuggestion.hidden = true
  }

  for (const tierReward of previewTierRewards) {
    tierReward.hidden = true
  }
}

function showCheckedTierPreviews() {
  const inputElements = document.querySelectorAll<HTMLInputElement>('.js-tier-suggestion')

  const allChecked = Array.from(inputElements).filter(el => el.checked)

  if (allChecked.length === 0) {
    document.getElementById('no-rewards-selected')!.hidden = false
  }

  for (const inputElement of allChecked) {
    showTierPreview(inputElement)
  }
}

function showTierPreview(input: HTMLInputElement) {
  const tierFrequency = input.getAttribute('data-tier-frequency')!
  const tierName = input.getAttribute('data-tier-name')!

  const tierPreview = document.getElementById(`preview-section-${tierFrequency}`)!
  tierPreview.hidden = false

  const tierSuggestionPreview = document.getElementById(`preview-${tierName}`)!
  tierSuggestionPreview.hidden = false

  const tierRewardPreview = document.getElementById(`preview-${input.id}`)!
  tierRewardPreview.hidden = false
}

function selectAllTiersForCategory(select: HTMLSelectElement, category: string) {
  const inputElements = document.querySelectorAll<HTMLInputElement>(`input[data-tier-category='${category}']`)

  for (const element of inputElements) {
    element.checked = true
  }

  select.setAttribute('data-function', 'deselect')
  select.textContent = select.getAttribute('data-all-selected-text')
}

function deselectAllTiersForCategory(select: HTMLSelectElement, category: string) {
  const inputElements = document.querySelectorAll<HTMLInputElement>(`input[data-tier-category='${category}']`)

  for (const element of inputElements) {
    element.checked = false
  }

  select.setAttribute('data-function', 'select')
  select.textContent = select.getAttribute('data-not-all-selected-text')
}

function buildInteractions(suggestionCheckbox: HTMLInputElement) {
  const actions = document.querySelector<HTMLInputElement>('.js-tier-building-actions')!
  const checkboxLabel = suggestionCheckbox.parentElement!
  const actionHash = {
    price_in_cents: suggestionCheckbox.getAttribute('data-tier-monthly-price'),
    frequency: suggestionCheckbox.getAttribute('data-tier-frequency'),
    description: checkboxLabel.textContent!.trim(),
    checked: suggestionCheckbox.checked,
  }

  const actionsList = actions.value ? JSON.parse(actions.value) : []
  actionsList.push(actionHash)
  actions.value = JSON.stringify(actionsList)
}
