import type {AutoCheckElement} from '@github/auto-check-element'

import {fire} from 'delegated-events'
import {observe} from '@github/selector-observer'
import {onInput} from '@github-ui/onfocus'
import {remoteForm} from '@github/remote-form'
import {sendData} from '../hydro-tracking'

function setCustomTierStatusIndicatorClass(target: Element, className: string) {
  target.classList.remove('status-indicator-success', 'status-indicator-loading', 'status-indicator-failed')
  target.classList.add(className)
}

remoteForm('.js-sponsors-custom-amount-form', async function (form, wants) {
  const statusIndicator = form.querySelector<HTMLElement>('.js-status-indicator')!
  const formGroup = form.querySelector<HTMLElement>('.form-group')!
  const errorNote = form.querySelector<HTMLElement>('.js-custom-amount-input-validation')!

  setCustomTierStatusIndicatorClass(statusIndicator, 'status-indicator-loading')

  try {
    await wants.json()
    errorNote.classList.remove('error')
    errorNote.hidden = true
    formGroup.classList.remove('errored')
    errorNote.textContent = ''
    setCustomTierStatusIndicatorClass(statusIndicator, 'status-indicator-success')
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    const message = error.response.json.message
    errorNote.classList.add('error')
    errorNote.hidden = false
    errorNote.textContent = message
    formGroup.classList.add('errored')
    setCustomTierStatusIndicatorClass(statusIndicator, 'status-indicator-failed')
  }
})

observe('.js-tier-shared-parent auto-check.js-tier-pricing-check', check => {
  const parent = check.closest<HTMLElement>('.js-tier-shared-parent')!
  const publishButton = parent.querySelector<HTMLButtonElement>('.js-publish-tier-button')
  // PublishButton will not exist if the user is signed out
  if (publishButton) {
    check.addEventListener('error', () => (publishButton.disabled = true))
    check.addEventListener('load', () => (publishButton.disabled = false))
  }
  fire(check.querySelector<HTMLInputElement>('input')!, 'input')
})

onInput('.js-sponsors-custom-amount-input', function (event) {
  const input = event.target as HTMLInputElement
  const customAmount = parseInt(input.value, 10)
  const container = input.closest('.js-sponsors-custom-amount-container')!
  const message = container.querySelector('.js-sponsors-custom-amount-message')!

  const tierMinimums = JSON.parse(message.getAttribute('data-tier-minimums')!) as number[]
  const closestTierAmount = tierMinimums.find(amount => customAmount >= amount)
  const minCustomTier = message.getAttribute('data-min-custom-tier-amount')
  const minCustomTierAmount = minCustomTier == null || minCustomTier === '' ? 0 : parseInt(minCustomTier, 10)

  const tierRewardPrefix = message.getAttribute('data-tier-reward-prefix') || ''
  const tierRewardSuffix = message.getAttribute('data-tier-reward-suffix') || ''
  const defaultRewardText = message.getAttribute('data-default-reward-text') || ''
  const chooseAmountText = message.getAttribute('data-choose-amount-text') || ''

  // There are three cases:
  //
  // 1. The user chose an invalid amount (e.g. below min): choose a different amount
  // 2. The user chose a valid amount above or at a published tier: tier-relevant rewards
  // 3. The user chose a valid amount but below any published tiers (or no published tiers exist): default rewards
  //
  // N.B. We support publishing tiers that are below the minimum set for custom amounts. A custom amount that
  // collides with a published tier is considered valid.
  const newMessage = (() => {
    if (!customAmount || (customAmount < minCustomTierAmount && customAmount !== closestTierAmount)) {
      return chooseAmountText
    } else if (closestTierAmount) {
      return `${tierRewardPrefix} $${closestTierAmount} ${tierRewardSuffix}`
    } else {
      return defaultRewardText
    }
  })()

  message.textContent = newMessage
})

function sponsorsNeedIdeasToggled(event: Event) {
  const details = event.currentTarget as HTMLElement
  const summaryEl = details.querySelector<HTMLElement>('.js-sponsors-toggle-need-help')!
  const isOpen = details.hasAttribute('open')
  let payload
  let hmac
  let hydroClientContext

  if (isOpen) {
    payload = summaryEl.getAttribute('data-open-hydro-click')
    hmac = summaryEl.getAttribute('data-open-hydro-click-hmac')
    hydroClientContext = summaryEl.getAttribute('data-open-hydro-client-context')
  } else {
    payload = summaryEl.getAttribute('data-closed-hydro-click')
    hmac = summaryEl.getAttribute('data-closed-hydro-click-hmac')
    hydroClientContext = summaryEl.getAttribute('data-closed-hydro-client-context')
  }

  payload = payload || ''
  hmac = hmac || ''
  hydroClientContext = hydroClientContext || ''

  sendData(payload, hmac, hydroClientContext)
}

observe('.js-sponsors-need-help-details', details => {
  details.addEventListener('toggle', sponsorsNeedIdeasToggled)
})

function updateTierFormFrequency(event: Event) {
  const radioButton = event.currentTarget as HTMLInputElement
  const container = radioButton.closest('.js-sponsors-tier-form')!

  const repositoriesMenu = container.querySelector('.js-repositories-menu') as HTMLElement
  repositoriesMenu.hidden = radioButton.value === 'one-time'

  const tierPricingCheck = container.querySelector('auto-check.js-tier-pricing-check') as AutoCheckElement
  const verifyUrl = new URL(tierPricingCheck.src, window.location.origin)
  verifyUrl.searchParams.set('frequency', radioButton.value)
  tierPricingCheck.src = verifyUrl.href
}

observe('.js-tier-frequency-radio-button', button => {
  button.addEventListener('change', updateTierFormFrequency)
})
