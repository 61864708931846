import {microtask} from '@github-ui/eventloop-tasks'
import {on} from 'delegated-events'

// Only allow up to a max org members to be featured.
async function limitUsersAndReposSelections({currentTarget}: {currentTarget: Element}) {
  limitFeaturedItemSelections(
    currentTarget,
    '.js-sponsors-meet-the-team-search-modal',
    getSelections('sponsors_listing[featured_users_selection][]'),
  )
  limitFeaturedItemSelections(
    currentTarget,
    '.js-sponsors-featured-sponsorships-search-modal',
    getSelections('sponsors_listing[featured_sponsorships_selection][]'),
  )
}

function getSelections(inputName: string) {
  return Array.from(document.getElementsByName(inputName))
    .filter(element => (element as HTMLInputElement).value)
    .map(element => parseInt((element as HTMLInputElement).value, 10))
}

// Limit the number of items that can be featured.
async function limitFeaturedItemSelections(root: Element, modalClassSelector: string, selections: number[]) {
  const modalContainer = root.querySelector(modalClassSelector)
  if (!modalContainer) return

  await microtask()

  const list = modalContainer.querySelector<HTMLUListElement>('.js-sponsors-featured-item-selection-list')
  if (!list) return

  let selectableItems: HTMLElement[] = Array.from(list.querySelectorAll<HTMLInputElement>('input[type=checkbox]'))
  if (selectableItems.length < 1) {
    selectableItems = Array.from(list.querySelectorAll<HTMLElement>('[role=menuitemcheckbox]'))
  }

  const limit = parseInt(list.getAttribute('data-featured-items-limit')!, 10)
  const atSelectionLimit = selections.length === limit

  for (const el of selectableItems) {
    const featureableId = parseInt(el.getAttribute('data-input-value')!, 10)
    const checked = selections.includes(featureableId)
    if (checked) {
      el.setAttribute('aria-checked', 'true')
    } else {
      el.setAttribute('aria-checked', 'false')
    }

    const disable = atSelectionLimit && !checked
    if (disable) {
      el.setAttribute('aria-disabled', 'true')
    } else {
      el.setAttribute('aria-disabled', 'false')
    }
  }

  const messageEl = modalContainer.querySelector<HTMLSpanElement>('.js-sponsors-featured-items-remaining')
  if (!messageEl) return

  const diff = limit - selections.length
  messageEl.textContent = `${diff} remaining`
  messageEl.classList.toggle('color-fg-danger', diff < 1)
}

on('click', '.js-sponsors-featured-item-toggle', function (event) {
  const toggleButton = event.currentTarget as HTMLButtonElement
  const form = toggleButton.form
  if (!form) return

  const isDisabled = toggleButton.getAttribute('aria-disabled') === 'true'
  if (isDisabled) return

  const wasChecked = toggleButton.getAttribute('aria-checked') === 'true'
  const newChecked = !wasChecked

  toggleButton.setAttribute('aria-checked', String(newChecked))

  const inputName = toggleButton.getAttribute('data-input-name')!
  const inputValue = toggleButton.getAttribute('data-input-value')!

  if (newChecked) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = inputName
    input.value = inputValue
    form.appendChild(input)
  } else {
    const input = form.querySelector<HTMLInputElement>(`input[name="${inputName}"][value="${inputValue}"]`)
    if (input) input.remove()
  }

  limitUsersAndReposSelections({currentTarget: form})
})

const observer = new MutationObserver(() =>
  limitUsersAndReposSelections({currentTarget: document.querySelector('.js-sponsors-dashboard-profile-form')!}),
)
for (const modal of document.querySelectorAll('.js-sponsors-dashboard-profile-search-results')) {
  observer.observe(modal, {childList: true})
}
