import {observe} from '@github/selector-observer'
import poof from '../behaviors/confetti'
import {wait} from '@github-ui/eventloop-tasks'

observe('.js-show-confetti-party', function (el) {
  poof()

  const dismissUrl = el.getAttribute('data-dismiss-url')
  if (dismissUrl) {
    const token = el.querySelector<HTMLInputElement>('.js-data-dismiss-url-csrf')!
    fetch(dismissUrl, {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Scoped-CSRF-Token': token.value,
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
  }
})

const GOAL_PROGRESS_WAIT_TIME = 500
const GOAL_HEART_CONTAINER_WAIT_TIME = 450
const GOAL_HEART_EXPLOSION_WAIT_TIME = 100

async function animateSponsorsGoalImpact(el: Element) {
  // Animate progress bar change
  const progressBar = el.querySelector<HTMLElement>('.js-sponsors-goals-impact-progress-bar')!
  const percentageImpact = progressBar.getAttribute('data-percentage-impact')
  await wait(GOAL_PROGRESS_WAIT_TIME)
  if (percentageImpact) progressBar.style.width = `${percentageImpact}%`

  // Render heart container
  const heartContainer = el.querySelector<HTMLElement>('.js-sponsors-goals-impact-heart')
  if (!heartContainer) return
  await wait(GOAL_HEART_CONTAINER_WAIT_TIME)
  heartContainer.hidden = false
  heartContainer.classList.add('anim-scale-in')

  // Start heart explosion animation
  const heart = heartContainer.querySelector<HTMLElement>('.sponsors-goals-heart-anim')!
  await wait(GOAL_HEART_EXPLOSION_WAIT_TIME)
  heart.classList.add('is-active')
}

observe('.js-sponsors-goals-impact', function (el) {
  animateSponsorsGoalImpact(el)
})
